import Base = require("Everlaw/Base");
import Button = require("Everlaw/UI/Button");
import Dom = require("Everlaw/Dom");
import Table = require("Everlaw/Table");
import ToggledSingleSelect = require("Everlaw/UI/ToggledSingleSelect");
import UI = require("Everlaw/UI");
import Util = require("Everlaw/Util");

export interface PageTableDispRange {
    start: number;
    end: number;
}

export interface PageTable {
    setInterval(interval: number): void;
    getInterval(): number;
    hasNext(): boolean;
    showNext(): void;
    hasPrevious(): boolean;
    showPrevious(): void;
    getDisplayedRange(): PageTableDispRange;
    totalSize(): number;
}

export class IntervalSelector {
    destroyables: Util.Destroyable[] = [];
    protected node: HTMLElement;
    protected intervalBox: HTMLDivElement;
    protected intervalSelect: ToggledSingleSelect<Base.Primitive<number>>;
    protected prevNextText: HTMLSpanElement;
    protected prevNext: Button.PrevNext;

    constructor(
        protected table: PageTable,
        protected intervalOptions: number[],
        protected onChange?: () => void,
    ) {
        this.intervalBox = Dom.div();
        const alignedInterval = UI.alignedContainer({
            content: this.intervalBox,
            vertical: true,
            cssClass: "left",
        });

        this.node = Dom.create("div", {
            class: "paginated-table__nav",
        });

        const prevNextBox = Dom.create(
            "div",
            {
                class: "h-spaced-8 paginated-table__buttons",
            },
            this.node,
        );
        const alignedPrevNext = UI.alignedContainer({
            content: prevNextBox,
            vertical: true,
            cssClass: "right",
        });
        Dom.place([alignedInterval, alignedPrevNext], this.node);

        const options = this.intervalOptions.map(
            (opt) => new Base.Primitive<number>(opt, opt + " results per page"),
        );
        this.intervalSelect = new ToggledSingleSelect({
            parent: this.intervalBox,
            options: options,
            onChange: (opt, index) => {
                if (this.table.getInterval() === this.intervalOptions[index]) {
                    return;
                }
                this.table.setInterval(this.intervalOptions[index]);
                this.update();
            },
            style: { float: "left" },
            default: options[0],
            width: 200,
        });
        this.destroyables.push(this.intervalSelect);

        this.prevNextText = Dom.create("span", { style: { display: "inline-block" } }, prevNextBox);
        const arrowBox = Dom.create("div", { style: { display: "inline-block" } }, prevNextBox);

        this.prevNext = new Button.PrevNext((dir) => {
            dir < 0 ? this.table.showPrevious() : this.table.showNext();
            this.update();
        });
        Dom.place(this.prevNext, arrowBox);
        // page navigation starts hidden, no results yet
        Dom.hide([this.intervalBox, this.prevNext, this.prevNextText]);
        this.destroyables.push(this.prevNext);
    }

    update(): void {
        const show = this.table.totalSize() > this.intervalOptions[0];
        Dom.show([this.intervalBox, this.prevNext, this.prevNextText], show);
        if (show) {
            this.prevNext.setDisabled(!this.table.hasPrevious(), "prev");
            this.prevNext.setDisabled(!this.table.hasNext(), "next");
            const range = this.table.getDisplayedRange();
            this.prevNextText.innerText =
                range.start + 1 + "–" + range.end + " of " + this.table.totalSize();
        }
        if (this.onChange) {
            this.onChange();
        }
    }

    destroy(): void {
        Util.destroy(this.destroyables);
    }

    getNode(): HTMLElement {
        return this.node;
    }
}

export class PaginatedTable<O extends Base.Object, D extends Table.RowData>
    extends Table<O, D>
    implements PageTable
{
    static readonly INTERVALS = [50, 100, 200, 500]; // options for how many results per page
    protected interval = PaginatedTable.INTERVALS[0]; // Maximum results per page
    protected currentPage = 0;
    protected prevNextText: HTMLElement;
    protected intervalSelector: IntervalSelector;
    protected prevNext: Button.PrevNext;
    protected filteredEntriesSize: number;

    constructor(
        params: Table.TableParams<O, D>,
        protected onShownChange?: () => void,
    ) {
        super(params);
        this.intervalSelector = new IntervalSelector(this, PaginatedTable.INTERVALS);
        Dom.place(
            Dom.div(
                { class: "paginated-table__interval-selector" },
                this.intervalSelector.getNode(),
            ),
            this.node,
            "after",
        );
    }

    override update(): void {
        super.update();
        // Update gets called by the superclass constructor before the interval selector is initialized
        if (this.intervalSelector) {
            this.intervalSelector.update();
        }
        if (this.onShownChange) {
            this.onShownChange();
        }
    }

    protected override getFilteredAndSortedEntries(then: (objs: O[]) => void): void {
        super.getFilteredAndSortedEntries((allFilteredEntries) => {
            this.filteredEntriesSize = allFilteredEntries.length;
            if (this.filteredEntriesSize <= this.currentPage * this.interval) {
                this.currentPage = this.lastPage();
            }
            then(
                allFilteredEntries.slice(
                    this.currentPage * this.interval,
                    (this.currentPage + 1) * this.interval,
                ),
            );
        });
    }

    protected lastPage(): number {
        // Get the number of pages, then subtract 1 to convert to zero-indexing
        return Math.max(1, Math.ceil(this.filteredEntriesSize / this.interval)) - 1;
    }

    setInterval(newInterval: number): void {
        this.currentPage = Math.floor((this.currentPage * this.interval) / newInterval);
        this.interval = newInterval;
        this.update();
    }

    getInterval(): number {
        return this.interval;
    }

    hasNext(): boolean {
        return this.currentPage < this.lastPage();
    }

    showNext(): void {
        if (this.hasNext()) {
            this.currentPage += 1;
        }
        this.update();
    }

    hasPrevious(): boolean {
        return this.currentPage > 0;
    }

    showPrevious(): void {
        if (this.hasPrevious()) {
            this.currentPage -= 1;
        }
        this.update();
    }

    getDisplayedRange(): PageTableDispRange {
        return {
            start: this.currentPage * this.interval,
            end: Math.min(this.totalSize(), (this.currentPage + 1) * this.interval),
        };
    }

    totalSize(): number {
        return this.filteredEntriesSize;
    }
}
