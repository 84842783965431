import Project = require("Everlaw/Project");
import Rest = require("Everlaw/Rest");
import Window = require("Everlaw/Window");
import { AuthSource } from "Everlaw/AuthSource";
import { CloudConnectorAuthSource } from "Everlaw/CloudConnectors/CloudConnectorAuthSource";
import { openCentered, OpenParams } from "Everlaw/Window";
export interface SecurityTokenResponse {
    clientId: string;
    state: string;
    redirectUri: string;
    token: string;
}

declare const window: Window & {
    redirectHandler: (value: AuthFinish) => void;
    /**
     * As of TS 4.3 opener is no longer typed as any, but as Window.
     * The lib.dom Window type does not include these methods here so we override the type of opener
     * to match the new intersection type declared here.
     */
    opener: typeof window;
};

// Fields of resolved OAuth promise
export interface AuthFinish {
    /**
     * Popup window that OAuth authentication flow was performed in.
     */
    authWindow: Window;
    /**
     * Info specific to the service that was authenticated with.
     * See CloudConnectorAuthConfig.java#getAuthAndServiceInfo
     */
    serviceInfo: unknown;
    /**
     * Access token that resulted from OAuth.
     * See CloudConnectorAuthConfig.java#getAuthAndServiceInfo
     */
    accessToken: string;
}

/**
 * Opens a new window at {@code url} to perform OAuth authentication.
 * Returned promise resolves to {@link AuthFinish} on successful Auth finish.
 * Promise rejects if the user's browser blocks the window or if the window is closed before OAuth
 * completes.
 *
 * This only supports a single OAuth flow at a time.
 * Do NOT invoke this function again until the returned promise resolves/rejects.
 * If support for simultaneous flows is needed, it can be added by making a window.redirectHandler
 * a map from OAuth flow to callback promise.
 *
 * Failure to authenticate does not currently reject the promise.
 * If that is useful we can account for that by storing a function to revoke the promise
 * on the opening window and invoking it via AuthRedirectFail.jsp
 *
 * @param url to start OAuth authentication at, usually a URL to an external service
 * @param errDesc short description of what this window is for (ex. "Zoom login"), used in error
 *     message if window is blocked
 * @param windowParams parameters to pass to {@link openCentered} to configure opened window
 * @param closeWindow whether to close the window after authentication is complete, default true
 */
export function performOAuth({
    url,
    errDesc,
    windowParams = {},
    closeWindow = true,
}: {
    url: URL;
    errDesc: string;
    windowParams?: OpenParams;
    closeWindow?: boolean;
}): Promise<AuthFinish> {
    // This promise resolves/rejects when resolve/reject are invoked.
    const oAuthPromise: Promise<AuthFinish> = new Promise((resolve, reject) => {
        let authenticating = true;
        window.redirectHandler = (finish: AuthFinish) => {
            authenticating = false;
            // Invoke any success callbacks.
            resolve(finish);
        };
        const popup = openCentered(
            url,
            errDesc,
            windowParams?.width,
            windowParams?.height,
            windowParams,
        );
        // popup can be null if the user's browser blocks popups
        if (!popup) {
            reject();
            return;
        }
        // Reject the promise if the popup is closed before authentication completes.
        // Unfortunately, the best current approach to detect if the popup is closed is to poll on an interval.
        const checkIfClosed = setInterval(() => {
            if (!popup || popup.closed) {
                clearInterval(checkIfClosed);
                authenticating && reject();
            }
        }, 1000);
    });
    // This ensures that the cleanup of the authWindow executes after any callbacks chained onto the returned promise.
    // This is important because they may rely on the authWindow in their callbacks.
    return oAuthPromise.then((finish: AuthFinish) => {
        // This will be executed after every callback chained onto the returned promise,
        // unless those callbacks do a similar paradigm (which is unlikely).
        oAuthPromise.finally(() => {
            if (closeWindow && finish.authWindow && !finish.authWindow.closed) {
                finish.authWindow.close();
            }
        });
        return finish;
    });
}

// This function is invoked from ConnectorAuthRedirect.jsp
export function afterAuth(serviceInfo: unknown, accessToken: string): void {
    if (window.opener === null) {
        // opener is null if this window was not opened by another or the opener was closed.
        // Since our current OAuth system always takes place via a popup this means the invoking window
        // is closed and we short circuit w/out logging an error.
        return;
    }
    if (window.opener.redirectHandler) {
        window.opener.redirectHandler({ authWindow: window, serviceInfo, accessToken });
    } else {
        throw new Error("Missing redirect handler");
    }
}

/*
 * When using OAuth workflow on the Org page (ex: creating Cloud Organizations or Microsoft Entra ID directories),
 * use default parcel provided in the JSP_PARAMS for storing the credentials.
 */
export function getCredentialCacheParcel(): Parcel {
    if (Project.CURRENT) {
        return Project.CURRENT.parcel;
    }
    return JSP_PARAMS.Server.defaultParcel;
}

/**
 * Legacy method of generating authentication token to kick off cloud file or folder uploads
 */
export function generateTokenAndRedirectUri(source: AuthSource): Promise<SecurityTokenResponse> {
    return Rest.get(`/parcel/${getCredentialCacheParcel()}/cloudAuth/generateSecurityToken.rest`, {
        authSource: AuthSource[source],
    });
}

/**
 * New method to generate authentication token for a cloud connector
 * This function enforces that the result of the OAuth reside on the current project parcel. If
 * there is no current project associated with your context, or you want to use a custom parcel,
 * please use {@link generateConnectorTokenAndRedirectUriForParcel} instead.
 * @param source CloudConnectorAuthSource type
 * @param sourceConfigId Unique within source and within an organization (if organization based)
 * @param orgId The Everlaw org ID associated with the config, if applicable (for per-organization configs)
 * @param serviceInfo Pass any extra information needed to know which endpoint to target to get access token
 */
export function generateConnectorTokenAndRedirectUri(
    source: CloudConnectorAuthSource,
    sourceConfigId: string | number,
    orgId?: number,
    serviceInfo?: string,
): Promise<SecurityTokenResponse> {
    return generateConnectorTokenAndRedirectUriForParcel(
        source,
        sourceConfigId,
        getCredentialCacheParcel(),
        orgId,
        serviceInfo,
    );
}

/**
 * Method to generate authentication token for a cloud connector with the modern cloud connector service
 * Allows for specifying what parcel is used for OAuth, which is the parcel that will hold the final credentials
 *
 * @param source CloudConnectorAuthSource type
 * @param authConfigSourceId Unique within source and within an organization (if organization based)
 * @param parcel Parcel to start the OAuth flow on, generally the current parcel
 * @param orgId The Everlaw org ID associated with the config, if applicable (for per-organization configs)
 * @param serviceInfo Extra information needed to generate the security token (varies by connector)
 */
export function generateConnectorTokenAndRedirectUriForParcel(
    source: CloudConnectorAuthSource,
    authConfigSourceId: string | number,
    parcel: Parcel,
    orgId?: number,
    serviceInfo?: string,
): Promise<SecurityTokenResponse> {
    return Rest.get(
        `/parcel/${parcel}/cloudAuth/generateConnectorSecurityTokenAndRedirectUri.rest`,
        {
            authSource: source,
            authConfigSourceId,
            orgId,
            serviceInfo,
        },
    );
}

// Set the standard OAuth protocol fields using a security token from the backend
function setOAuthParams(url: URL, stResponse: SecurityTokenResponse): void {
    url.searchParams.set("client_id", stResponse.clientId);
    url.searchParams.set("redirect_uri", stResponse.redirectUri);
    url.searchParams.set("state", stResponse.state);
}

export function microsoftAdminConsentUrl(stResponse: SecurityTokenResponse): URL {
    const url = new URL("https://login.microsoftonline.com/common/adminconsent");
    setOAuthParams(url, stResponse);
    return url;
}

export function microsoftGraphAuthURL(stResponse: SecurityTokenResponse, scopes = ""): URL {
    const url = new URL("https://login.microsoftonline.com/common/oauth2/v2.0/authorize");
    setOAuthParams(url, stResponse);
    url.searchParams.set("scope", scopes);
    url.searchParams.set("response_type", "code");
    // Allow the user to select the account to use instead of just proceeding with the signed in account
    url.searchParams.set("prompt", "select_account");
    return url;
}

export function boxAuthUrl(stResponse: SecurityTokenResponse): URL {
    const url = new URL("https://account.box.com/api/oauth2/authorize");
    url.searchParams.set("response_type", "code");
    setOAuthParams(url, stResponse);
    return url;
}

export function dropboxAuthURL(stResponse: SecurityTokenResponse): URL {
    const url = new URL("https://www.dropbox.com/oauth2/authorize");
    setOAuthParams(url, stResponse);
    url.searchParams.set("response_type", "code");
    url.searchParams.set("token_access_type", "offline");
    return url;
}

export function googleAuthURL(stResponse: SecurityTokenResponse, scopes = ""): URL {
    const url = new URL("https://accounts.google.com/o/oauth2/v2/auth");
    setOAuthParams(url, stResponse);
    url.searchParams.set("scope", scopes);
    url.searchParams.set("access_type", "offline");
    url.searchParams.set("prompt", "consent");
    url.searchParams.set("response_type", "code");
    url.searchParams.set("enable_granular_consent", "true");
    return url;
}

export function sharefileAuthUrl(stResponse: SecurityTokenResponse): URL {
    const url = new URL("https://secure.sharefile.com/oauth/authorize");
    setOAuthParams(url, stResponse);
    url.searchParams.set("response_type", "code");
    return url;
}

export function slackAuthURL(stResponse: SecurityTokenResponse, scopes = ""): URL {
    const url = new URL("https://slack.com/oauth/v2/authorize");
    url.searchParams.set("user_scope", scopes);
    setOAuthParams(url, stResponse);
    url.searchParams.set("response_type", "code");
    return url;
}

export function getZoomAuthURL(stResponse: SecurityTokenResponse): URL {
    const url = new URL("https://zoom.us/oauth/authorize");
    url.searchParams.set("response_type", "code");
    setOAuthParams(url, stResponse);
    return url;
}

export function getJiraAuthURL(stResponse: SecurityTokenResponse, scopes = ""): URL {
    const url = new URL("https://auth.atlassian.com/authorize");
    setOAuthParams(url, stResponse);
    url.searchParams.set("audience", "api.atlassian.com");
    url.searchParams.set("scope", scopes);
    url.searchParams.set("response_type", "code");
    url.searchParams.set("prompt", "consent");
    return url;
}

export function getZendeskAuthURL(
    subdomain: string,
    stResponse: SecurityTokenResponse,
    scopes = "",
): URL {
    const url = new URL(`https://${subdomain}.zendesk.com/oauth/authorizations/new`);
    url.searchParams.set("response_type", "code");
    url.searchParams.set("scope", scopes);
    setOAuthParams(url, stResponse);
    return url;
}
