export enum AuthSource {
    LOCAL,
    BOX,
    DROPBOX,
    GOOGLE_DRIVE,
    DIRECT_LINK,
    VAULT,
    SHAREFILE,
    GRAPH_USER,
    GRAPH_APP,
    SLACK,
}
